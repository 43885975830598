import React from 'react'
import './Sellect_Cabs.css'


 const imageUrl = 'https://5.imimg.com/data5/SH/PP/GLADMIN-43451090/toyota-innova-suv-taxi-in-satna-1000x1000.png';

const Sellect_Cabs = ({Cabs}) => {
  return (
   <div className="Select_cabs">
      <div className="Select_cabs_title">
         Select a Cab for tour
      </div>
      <div className="Select_cabs_grid">
      <div className="card-grid">
      {Cabs.map((card, index) => (
        <div key={index} className={`card ${index % 2 === 0 ? 'left' : 'right'}`}>
        <div className="Cab_details_row">
        <img src={Cabs[index].Image} alt={`Image ${index + 1}`} className="Cab_image"/>
        <div className="Cab_details_column">
         <div className="Cab_title">
         {Cabs[index].Name}
          </div>
          <div className="Seater">
            {Cabs[index].Seater} Seater
          </div>
          <div className="Cab_price">
          {Cabs[index].Price}
          </div>
         </div>
        </div>
        </div>
      ))}
    </div>
      </div>
   </div>
  )
}

export default Sellect_Cabs