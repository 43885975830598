import React from "react";
import "./Package_detail_container.css";
import Package_detail_contact_us from "../Package_detail_contact_us/Package_detail_contact_us";
import Package_main_details from "./Package_main_details/Package_main_details";
import Package_description from "./Package_description/Package_description";
import Amenities from "./Amenities/Amenities";
import Timings_table from "./Timings_table/Timings_table";
import Sellect_Cabs from "./Sellect_Cabs/Sellect_Cabs";
import Included from "./Included/Included";
import Excluded from "./Excluded/Excluded";
import Notice from "./Notice/Notice";
import Charges from "./Charges/Charges";
import Places from "./Places/Places";
import Feedback from "../Feedback/Feedback";
import { collection, onSnapshot, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../Firebase";

const Package_detail_container = ({ packageData, index }) => {
 
  const [Cabs, setCabs] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchCabs = async () => {
    setLoading(true);
    const sanitizedPackageName = packageData.Name.replace("/", "-");
const q = query(collection(db, "Packages", sanitizedPackageName, "Cabs"));

    onSnapshot(q, (querySnapshot) => {
      const Cabs = [];
      querySnapshot.forEach((doc) => {
        Cabs.push(doc.data());
      });
      setCabs([...Cabs]);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchCabs();
  }, []);
 
 
  return (
    <div className="Row">
      <div className="Package_detail_container">
        <Package_main_details
          title={packageData.Name}
          organizer={packageData.Tour_organizer}
          main_details={packageData.Main_details}
        />
        <Places places={packageData.Places_covering} />
        <Package_description description={packageData.Description} />
        <Amenities amenities={packageData.Amenities} />
        <Timings_table timings={packageData.Timings} />
        {Cabs && Cabs.length > 0 && <Sellect_Cabs Cabs={Cabs} />}

        <Included includes={packageData.Includes} />
        <Excluded excludes={packageData.Excluded}/>
        <Notice notice = {packageData.Points_to_note}/>
        <Charges charges = {packageData.Charges}/>
      </div>
      <div className="Column_">
        <Package_detail_contact_us  price = {packageData.Price}/>
        <Feedback Packagename={packageData.Name}/>
      </div>
    </div>
  );
};

export default Package_detail_container;
