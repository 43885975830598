import React, { useEffect } from "react";
import "./Blog_detail.css";
import Place6 from "../../images/Place_2.webp";
import Blog_detail_block from "./Blog_detail_block/Blog_detail_block";

import { AiOutlineCalendar } from "react-icons/ai";
import Blog_tile from "./Blog_tile/Blog_tile";
import { useParams } from "react-router-dom";
import BlogsjsonData from "../Blogs_data";

const Blog_detail = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);


  const { id } = useParams(); // Retrieve the id parameter from the URL
  const blogIndex = id;

  const blog = BlogsjsonData[id];
  const blogs = [];
  return (
    <div className="Blog_detail">
      <div className="BLog_detail_date">
        <AiOutlineCalendar />
        
        {blog.timings}
      </div>
      <div className="Blog_detail_title">
  {blog.title} - Blog 0{parseInt(id) + 1}
</div>


      <div
        className="Blog_detail_image"
        style={{ backgroundImage: `url(${blog.Horizontalimage})` }}
      ></div>
      <div className="Blog_blocks_tiles">
        <div className="Blog_blocks">
          {BlogsjsonData[id].blocks.map(
            (block, index) =>
              index !== id && (
                <Blog_detail_block key={index} index={index} block={block} />
              )
          )}
        </div>
        <div className="Blog_tiles">
          <div className="Blog_tiles_title">Other blogs</div>
          {BlogsjsonData.map(
            (blog, index) =>
              index !== id && (
                <Blog_tile key={index} index={index} blog={blog} />
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default Blog_detail;
