import React, { useState } from "react";
import "./Callback.css";
import { toast } from "react-toastify";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../Firebase";

const Callback = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [loading, setLoading] = useState(false);

  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };
  const isValidPhoneNumber = (phoneNumber) => {
    // Regular expression for valid phone number
    const phonePattern = /^(\+?\d{10}|\+?\d{11}|\+?\d{12})$/;
    return phonePattern.test(phoneNumber);
  };
  
  const isButtonClickable =
    name.length > 4 && isValidEmail(email) && isValidPhoneNumber(phone);

  const handleRequestCallback = async () => {
    if (isButtonClickable) {
      setLoading(true);

      await setDoc(
        doc(db, "Callbacks", phone),
        {
          Username: name,
          Email: email,
          Phone: phone,
        },
        { merge: true }
      )
        .then(() => {
          toast("Callback recieved successfully", {
            position: toast.POSITION.BOTTOM_LEFT,
            className: "toast-message",
          });

          setName("");
          setEmail("");
          setPhone("");
        })
        .catch((error) => {
          toast(error.toString(), {
            position: toast.POSITION.BOTTOM_LEFT,
            className: "toast-message",
          });
        });

      setLoading(false);
    }
  };

  return (
    <div className="Callback_block">
      <input
        placeholder="Name..."
        className="Name_field_callback"
        required
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <input
        placeholder="Email..."
        className="Email_field_callback"
        required
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        placeholder="Phone..."
        className="Phone_field_callback"
        required
        type="text"
        pattern="[0-9+]*"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />

      <div
        className="Callback_button"
        onClick={handleRequestCallback}
        style={{ opacity: isButtonClickable ? 1 : 0.6 }}
      >
        Request Call back
      </div>
    </div>
  );
};

export default Callback;
