import React from 'react';
import './Amenities.css';

const chunkArray = (arr, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunks.push(arr.slice(i, i + chunkSize));
  }
  return chunks;
};

const Amenities = ({ amenities }) => {
  // Ensure amenities is an array and has at least one item, and each item is not an empty string
  if (
    !Array.isArray(amenities) ||
    amenities.length === 0 ||
    !amenities.every((amenity) => amenity !== "")
  ) {
    return null; // Render nothing if amenities are missing, empty, or contain empty strings
  }

  const dividedAmenities = chunkArray(amenities, Math.ceil(amenities.length / 3));

  return (
    <div className="Amenities">
      <div className="Amenities_title">Amenities</div>
      <div className="Amenities_">
        {dividedAmenities.map((chunk, chunkIndex) => (
          <div key={chunkIndex} className="amenities-grid">
            {chunk.map((amenity, index) => (
              <div key={index} className="amenity-item">
                {/* Uncomment and use FontAwesome icons if needed */}
                {/* <FontAwesomeIcon icon={amenity.icon} className="amenity-icon" /> */}
                <span className="amenity-text">{amenity}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Amenities;
