import React from 'react'
import './Package_description.css'
const Package_description = ({description}) => {
  return (
   <div className="Package_description">
      <div className="Desc_title">Description</div>
      {description.map((text, index) => (
        <li key={index} className='Package_desc'>{text}</li>
      ))}
      <div className="Vertical_divider_"></div>
    </div>
  )
}

export default Package_description