import React from "react";
import "./All_blogs.css";
import { Link } from "react-router-dom";
import BlogsjsonData from '../Blogs_data';

const renderBlogs = () => {
  const blogs = [];

  for (let i = 0; i < BlogsjsonData.length; i++) {
    const blog = BlogsjsonData[i];
    blogs.push(
      <Link
        key={i}
        to={`/Blog_detail/${i}`} // Pass the index as a URL parameter
      >
        <div
          className="Blog"
          style={{ backgroundImage: `url(${blog.Verticalimage})` }} // Use the image URL from the data
        >
          <div className="Sized_box_width400"></div>

          <div className="Blog_title_date">
            <div className="Blog_title">{blog.title}</div>
            <div className="Blog_date">{blog.date}</div>
          </div>
        </div>
      </Link>
    );
  }

  return blogs;
};

const All_blogs = () => {
  return (
    <div className="All_blogs">
      <div className="Our_packages">Blogs</div>
      <div className="Packages_desc">
        "Blogs are like books, waiting to be written, pages turned, and chapters
        completed."
      </div>

      <div className="All_packages_frame">{renderBlogs()}</div>

      <div className="Sized_box_height100"></div>
    </div>
  );
};

export default All_blogs;
