import React, { useState } from "react";
import "./Package_detail_contact_us.css";
import Contact_us from "./Contact_us/Contact_us";
import Callback from "./Callback/Callback";

const Package_detail_contact_us = ({ price }) => {
  const [contactus, setcontactus] = useState(true);

  return (
    <div className="Package_detail_contact_us">
      {price && price.length > 0 ? (
        <div>
          <div className="Price_title">Price</div>
          <div className="Price_">{price}/-</div>
        </div>
      ) : (
        <div></div>
      )}
      <div className="Contact_us_callback">
        <div
          className={
            contactus === true
              ? "Contact_us_title selected"
              : "Contact_us_title"
          }
          onClick={() => setcontactus(true)}
        >
          Contact us
        </div>
        |
        <div
          className={
            contactus === false
              ? "Contact_us_title selected"
              : "Contact_us_title"
          }
          onClick={() => setcontactus(false)}
        >
          Request callback
        </div>
      </div>
      {contactus === true ? <Contact_us /> : <Callback />}
    </div>
  );
};

export default Package_detail_contact_us;
