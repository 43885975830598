import { initializeApp } from 'firebase/app';
import {getAuth} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
import { getStorage } from 'firebase/storage';
const firebaseConfig = {
  apiKey: "AIzaSyBYna_gruDTYq-tHI_VvZENqecg_bXzSns",
  authDomain: "hyderabadtours-1461d.firebaseapp.com",
  projectId: "hyderabadtours-1461d",
  storageBucket: "hyderabadtours-1461d.appspot.com",
  messagingSenderId: "453893628012",
  appId: "1:453893628012:web:b051d9a1169398a9263849",
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
export default storage;
export const db = getFirestore(app);
export const authentication = getAuth(app)
