import React from 'react'
import Packages from './Packages/Packages'
import './Home.css'
import Blogs from './Blogs/Blogs'
import Welcome from './Welcome/Welcome'
import Feedbacks from './Feedbacks/Feedbacks'
const Home = () => {
  return (
   <div className="Home">
    <Welcome/>
      <Packages/>
      <Feedbacks/>
      <Blogs/>
      
   </div>
  )
}

export default Home