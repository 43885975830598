import React from "react";
import "./Blog_tile.css";
import { Link } from "react-router-dom";

const Blog_tile = (props) => {
  const { index, blog } = props;

  return (
    <Link to={`/Blog_detail/${index}`} className="link">
      <div className="Blog_tile">
        <div
          className="Blog_tile_image"
          style={{ backgroundImage: `url(${blog.Horizontalimage})` }}
        ></div>
        <div className="Blog_tile_details">
          <div className="Blog_tile_name">{blog.title}</div>
          <div className="Blog_tile_date">{blog.date}</div>
        </div>
      </div>
    </Link>
  );
};

export default Blog_tile;
