import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { Fab } from 'react-tiny-fab';
import './Whatsapp_fab.css'
import { AiOutlineWhatsApp } from 'react-icons/ai';
const WhatsAppFab = () => {
  const handleWhatsAppClick = () => {
    // Replace this with your logic to open the WhatsApp chat
    // For example, you can use a URL like "https://wa.me/1234567890"
    window.open('https://wa.me/9666358080', '_blank');
  };

  return (
    <div className="Whatsappfab" onClick={handleWhatsAppClick}>
    
        <AiOutlineWhatsApp />
      </div>
  );
};

export default WhatsAppFab;
