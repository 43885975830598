import React from 'react';
import './Places.css';
import { IoMdCheckmark } from 'react-icons/io';

const Places = ({ places }) => {
  // Check if places is not empty and every place is not an empty string
  const shouldRenderPlaces =
    places &&
    Array.isArray(places) &&
    places.length > 0 &&
    places.every(place => place !== "");

  // If places do not meet the criteria, return null or a message
  if (!shouldRenderPlaces) {
    return null; // Or you can return a message like 'No valid places to display'
  }

  const placesPerColumn = Math.ceil(places.length / 3);

  const renderColumn = (startIndex, endIndex) => {
    return places.slice(startIndex, endIndex).map((place, index) => (
      <div key={index} className="Place_block">
        <IoMdCheckmark className="Place_icon" />
        <span className="Place_text">{place}</span>
      </div>
    ));
  };

  return (
    <div className="Places">
      <div className="Places_title">Places we are covering</div>
      <div className="Places_grid">
        <div className="Places_column">{renderColumn(0, placesPerColumn)}</div>
        <div className="Places_column">{renderColumn(placesPerColumn, placesPerColumn * 2)}</div>
        <div className="Places_column">{renderColumn(placesPerColumn * 2, places.length)}</div>
      </div>
    </div>
  );
};

export default Places;
