import React, { useState } from "react";
import "./Package_card.css";
import Package_feature from "../Package_feature/Package_feature";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IoIosArrowForward } from "react-icons/io";
import {
  faCar,
  faCoffee,
  faClock,
  faMonument,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const Feature_data = [
  { icon: <FontAwesomeIcon icon={faCar} />, text: "Cab" },
  { icon: <FontAwesomeIcon icon={faCoffee} />, text: "Breakfast" },
  { icon: <FontAwesomeIcon icon={faClock} />, text: "4 days" },
  { icon: <FontAwesomeIcon icon={faMonument} />, text: "8 Places" },
];

const Package_card = ({ index , Images, title, price, organizer, Main_details }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const shouldRenderFeatures =
    Array.isArray(Main_details) &&
    Main_details.length > 0 &&
    Main_details.every((item) => item !== "");
  return (
    <div className="Package_card">
      <div className="slider-container">
        <div
          className="slider"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {Images.map((image, index) => (
            <div className="Package_image_container" key={index}>
              <img
                src={image}
                alt={`Image ${index}`}
                className="Package_img"
              />
            </div>
          ))}
        </div>
        <div className="slider-nav">
          <button
            className="slider-arrow left-arrow"
            onClick={() => {
              if (currentIndex > 0) {
                setCurrentIndex(currentIndex - 1);
              }
            }}
          >
            &larr;
          </button>
          <button
            className="slider-arrow right-arrow"
            onClick={() => {
              if (currentIndex < Images.length - 1) {
                setCurrentIndex(currentIndex + 1);
              }
            }}
          >
            &rarr;
          </button>
        </div>
      </div>

      <div className="Package_card_details">
        <div className="Package_organizer">Tour organizer : {organizer}</div>
        <div className="Package_title">{title}</div>
        
      {shouldRenderFeatures && (
        <div className="Package_features_row">
          {Main_details.map((Detail, index) => (
            <div className="Package_feature_row" key={index}>
              <div className="empty"></div>
              <Package_feature
                index={index}
                icon={Feature_data[index].icon}
                text={Main_details[index]}
              />

              {index !== 3 ? <div className="divider" key={`divider-${index}`}></div> : <div></div>}
            </div>
          ))}
        </div>
      )}

        <div className="Horizontal_divider"></div>
          <div className="Price_readmore">
            
        {price && price !== null && price !== "" && (
            <div className="Price_couple">
              <div className="Package_price">₹{price}</div>
              <div className="Per_couple">/couple</div>
            </div>
            
        )}
            <Link className="link" to={`/Package_detail/${index}`}>
              <div className="Readmore">
                Read More
              </div>
            </Link>
          </div>
      </div>
    </div>
  );
};

export default Package_card;
