import React from 'react'
import './Package_detail_images.css'

import Place1 from "../../../images/Place_1.jpg";
import Place2 from "../../../images/Place_2.webp";
import Place3 from "../../../images/Place_3.jpg";
import Place4 from "../../../images/Place_4.avif";
import Place5 from "../../../images/Place_5.webp";
import Place6 from "../../../images/Place_6.jpg";


const Package_detail_images = ({ Images }) => {
   
  return (
   <div className="Package_detail_images">
       <div className="Grid">
         <div className="Left_square">
         <div className="Left_column">
           {Images.slice(1, 3).map((imageUrl, index) => (
             <div key={index} className="Left_image">
               <img src={imageUrl} alt={`Image ${index + 1}`} className="Package_image"/>
             </div>
           ))}
         </div>
         <div className="Left_column">
           {Images.slice(3, 5).map((imageUrl, index) => (
             <div key={index} className="Left_image">
               <img src={imageUrl} alt={`Image ${index + 1}`} className="Package_image"/>
             </div>
           ))}
         </div>
         </div>
         <div className="Center_column">
           <div className="Center_image">
             <img src={Images[0]} alt="Center Image" className="Package_center_image"/>
           </div>
         </div>
        <div className="Right_square">
        <div className="Right_column">
           {Images.slice(5, 7).map((imageUrl, index) => (
             <div key={index} className="Right_Image">
               <img src={imageUrl} alt={`Image ${index + 4}`} className="Package_image"/>
             </div>
             
           ))}
         </div>
         <div className="Right_column">
           {Images.slice(7, 9).map((imageUrl, index) => (
             <div key={index} className="Right_Image">
               <img src={imageUrl} alt={`Image ${index + 4}`} className="Package_image"/>
             </div>
             
           ))}
         </div>
        </div>
       </div>
   </div>
  )
}

export default Package_detail_images