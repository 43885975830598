import React, { useEffect, useState } from "react";
import "./Package_detail.css";
import Package_detail_images from "./Package_detail_images/Package_detail_images";
import Package_detail_container from "./Package_detail_container/Package_detail_container";
import Sellect_Cabs from "./Package_detail_container/Sellect_Cabs/Sellect_Cabs";
import { useParams } from "react-router-dom";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../Firebase";
import Loader from "../Loader/Loader";

const Package_detail = () => {
  const [Packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchPackages = async () => {
    setLoading(true);
    const q = query(collection(db, "Packages"),orderBy("createdAt", "asc"));
    onSnapshot(q, (querySnapshot) => {
      const packagesData = [];
      querySnapshot.forEach((doc) => {
        packagesData.push(doc.data());
      });
      setPackages(packagesData);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  const { id } = useParams();
  const packageIndex = id;

  return (
    <div className="Package_detail">
      {loading ? (
        <Loader/>
      ) : Packages[packageIndex] ? (
        <>
          <Package_detail_images Images={Packages[packageIndex].Images} />
          <Package_detail_container 
            packageData={Packages[packageIndex]}
            index={packageIndex} />
        </>
      ) : (
        <Loader/>
      )}
      
    </div>
  );
};

export default Package_detail;
