import React from 'react'
import './Charges.css'

const Charges = ({ charges }) => {
  // Check if charges.Charges_points is an array and all items are not empty strings
  const allChargesPointsValid =
    Array.isArray(charges.Charges_points) &&
    charges.Charges_points.length > 0 &&
    charges.Charges_points.every((text) => text !== "");

  // Ensure charges object is not missing and all Charges_points are valid
  if (!charges || !allChargesPointsValid) {
    return null; // Render nothing if charges are missing or contain empty strings
  }

  return (
    <div className="Charges">
      <div className="Charges_title">
        Charges on Deviations, if any!
      </div>
      <div className="Charges_text">
        {charges.Description}
      </div>
      <div className="Charges_list">
        <ul>
          {charges.Charges_points.map((text, index) => (
            <li key={index} className='Charge'>{text}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Charges;
