import React from 'react'
import './Notice.css'
import { TfiWrite } from 'react-icons/tfi';
const Notice = ({notice}) => {
  return (
   <div className="Notice">
      <div className="Notice_title">
      <div className="Red_dot">
            
            </div>
         Points to note 
      </div>


      <ul>
      {notice.map((Note, index) => (
        <li key={index} className='Excluded_text'>{Note}</li>
      ))}
    </ul>



   </div>
  )
}

export default Notice