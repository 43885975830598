import React, { useEffect, useState } from "react";
import Package_card from "./Package_card/Package_card";
import "./Packages.css";
import Place1 from "../../../images/Place_1.jpg";
import Place2 from "../../../images/Place_2.webp";
import Place3 from "../../../images/Place_3.jpg";
import Place4 from "../../../images/Place_4.avif";
import Place5 from "../../../images/Place_5.webp";
import Place6 from "../../../images/Place_6.jpg";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { db } from "../../Firebase";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";

const Packages = () => {
  const [Packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchPackages = async () => {
    setLoading(true);
    const q = query(collection(db, "Packages"),orderBy("createdAt", "asc"));
    onSnapshot(q, (querySnapshot) => {
      const Packages = [];
      querySnapshot.forEach((doc) => {
        Packages.push(doc.data());
      });
      setPackages([...Packages]);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  const displayedPackages = Packages.slice(0, 6);

  return (
    <div className="Packages">
      <div className="Row_spacebetween">
        <div className="sized_box_100"></div>
        <div className="Our_packages">Our Featured Packages</div>
        <Link to="/All_packages" className="link">
          <div className="View_all">
            View All
            <IoIosArrowForward />
          </div>
        </Link>
      </div>
      <div className="Packages_desc">
        Embark on unforgettable journeys with our diverse tour packages,
        blending cultural immersion and breathtaking landscapes for an
        unparalleled travel experience.
      </div>

      <div className="Packages_frame">
        {Packages.map((packageItem, index) => (
          <Package_card
            key={index}
            index={index}
            title={Packages[index].Name}
            Images={Packages[index].Images}
            organizer={Packages[index].Tour_organizer}
            price={Packages[index].Price}
            Main_details={Packages[index].Main_details}
          />
        ))}
      </div>
    </div>
  );
};

export default Packages;
