import React from 'react'
import './Blog_detail_block.css'
import Place6 from "../../../images/Place_6.jpg";
const Blog_detail_block = (props) => {
   
  const { index, block } = props;
  return (
   <div className="Blog_detail_block">
   <div className="Blog_detail_block_image" style={{ backgroundImage: `url(${block.blockImage})` }}></div>
      <div className="Blog_detail_block_title">
         {block.blockTitle}

      </div>
      <div className="Blog_detail_block_desc">
     
      {block.blockDescription}</div>

   </div>
  )
}

export default Blog_detail_block