import React, { useRef } from "react";
import "./Blogs.css";
import Place1 from "../../../images/Place_1.jpg";
import Place2 from "../../../images/Place_2.webp";
import Place3 from "../../../images/Charminar_potrait.jpg";
import Place4 from "../../../images/Place_4.avif";
import Place5 from "../../../images/Hotel_1.webp";
import Place6 from "../../../images/Place_6.jpg";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import BlogsjsonData from "../../Blogs_data";
const Blogs = () => {
  const images = [
    Place3,
    Place3,
    Place3,
    Place3,
    Place3,
    Place3,
    Place3,
    Place3,
    Place3,
  ];

  const containerRef = useRef(null);
  let startX;
  let scrollLeft;

  const handleMouseDown = (e) => {
    e.preventDefault(); // Prevent the default link behavior
    startX = e.pageX - containerRef.current.offsetLeft;
    scrollLeft = containerRef.current.scrollLeft;
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    e.preventDefault(); // Prevent the default link behavior
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust the scrolling speed
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const renderBlogs = () => {
    const blogs = [];
  
    for (let i = 0; i < BlogsjsonData.length; i++) {
      const blog = BlogsjsonData[i];
  
      if (blog && blog.Verticalimage) {
        blogs.push(
          <Link
            key={i}
            to={`/Blog_detail/${i}`}
          >
            <div
              key={i}
              className="Blog"
              style={{ backgroundImage: `url(${blog.Verticalimage})` }}
            >
              <div className="Sized_box_width400"></div>
              <div className="Blog_title_date">
                <div className="Blog_title">{blog.title}</div>
                <div className="Blog_date">{blog.date}</div>
              </div>
            </div>
          </Link>
        );
      }
    }
  
    return blogs;
  };
  

  return (
    <div className="Blogs">
      <div className="Row_spacebetween">
        <div className="sized_box_100"></div>
        <div className="Our_packages">Blogs</div>
        <Link to="/All_blogs" className="link">
          <div className="View_all">
            View All
            <IoIosArrowForward />
          </div>
        </Link>
      </div>
      <div className="Packages_desc">
        "Blogs are like books, waiting to be written, pages turned, and chapters
        completed."
      </div>
      <div
        className="scroll-container"
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseUp}
        onMouseUp={handleMouseUp}
      >
        {renderBlogs()}
      </div>

      <div className="Sized_box_height100"></div>
    </div>
  );
};

export default Blogs;
