import React, { useEffect, useState } from "react";
import "./All_packages.css";
import Place1 from "../../images/Place_1.jpg";
import Place2 from "../../images/Place_2.webp";
import Place3 from "../../images/Place_3.jpg";
import Place4 from "../../images/Place_4.avif";
import Place5 from "../../images/Place_5.webp";
import Place6 from "../../images/Place_6.jpg";
import { IoIosArrowForward } from "react-icons/io";
import Package_card from "../Home/Packages/Package_card/Package_card";
import { db } from '../Firebase';
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
const All_packages = () => {
  const images = [
    Place3,
    Place4,
    Place1,
    Place2,
    Place1,
    Place2,

    Place3,
    Place4,
    Place1,
    Place2,
    Place1,
    Place2,
  ];
const [Packages, setPackages] = useState([])
  const [loading, setLoading] = useState(false);
    const fetchPackages = async () => {
    setLoading(true);
    const q = query(collection(db, "Packages"),orderBy("createdAt", "asc"));
    onSnapshot(q, (querySnapshot) => {
      const Packages = [];
      querySnapshot.forEach((doc) => {
        Packages.push(doc.data());
      });
      setPackages([...Packages]);
       setLoading(false);
    });
  
  };

  useEffect(() => {
    fetchPackages();
  }, []);


  return (
    <div className="All_packages">
      <div className="All_packages_Row_spacebetween">
        <div className="Our_All_packages">Our Packages</div>
       
      </div>
      <div className="All_packages_desc">
        Embark on unforgettable journeys with our diverse tour All_packages,
        blending cultural immersion and breathtaking landscapes for an
        unparalleled travel experience.
      </div>

      <div className="All_packages_frame">
        {Packages.map((image, index) => (
          <Package_card
            key={index}
            index ={index}
            title={Packages[index].Name}
            Images={Packages[index].Images}
            organizer={Packages[index].Tour_organizer}
            price={Packages[index].Price}
            Main_details={Packages[index].Main_details}
          />
        ))}
      </div>
    </div>
  );
};

export default All_packages;
