import React from 'react'
import './Included.css'


const Included = ({includes}) => {
  return (
   <div className="Included">
      <div className="Included_title">
         Whats Included?
      </div>
      <div className="Included_list">
      <ul>
      {includes.map((text, index) => (
        <li key={index} className='Included_text'>{text}</li>
      ))}
    </ul>
      </div>
   </div>
  )
}

export default Included