import React, { useEffect, useState } from "react";
import "./Contact_us.css";
import { HiOutlinePhone } from "react-icons/hi";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../../Firebase";

const PhoneNumberLink = ({ phoneNumber }) => {
  return (
    <a href={`tel:${phoneNumber}`} className="phone-icon red">
      <HiOutlinePhone />
    </a>
  );
};

const Contact_us = () => {
  const [phonenumbers, setPhonenumbers] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const fetchPhonenumbers = async () => {
    setLoading(true);
    const q = query(collection(db, "Contact_us"), orderBy("createdAt", "asc"));
    onSnapshot(q, (querySnapshot) => {
      const phoneNumbersData = [];
      querySnapshot.forEach((doc) => {
        phoneNumbersData.push(doc.data().Phone);
      });
      setPhonenumbers([...phoneNumbersData]);
      setLoading(false);
    });
  };
  
useEffect(() => {
    fetchPhonenumbers();
  }, []);

  const handleContactClick = () => {
    if (phonenumbers.length > 0) {
      window.location.href = `tel:${phonenumbers[0]}`;
    }
  };

  return (
    <div className="Contact_us">
      {phonenumbers.map((phoneNumber, index) => (
        <div key={index} className="Phone_no_block">
          <div className="Phone_no">{phoneNumber}</div>
          <PhoneNumberLink phoneNumber={phoneNumber} />
        </div>
      ))}

      <div className="Contact_us_button" onClick={handleContactClick}>
        Contact us
      </div>
    </div>
  );
};

export default Contact_us;
