import React from 'react';
import './Timings_table.css';

const Timings_table = ({ timings }) => {
  // Convert the object to an array of key-value pairs
  const timingsArray = Object.entries(timings);

  // Sort the timingsArray by converting time strings to Date objects
  timingsArray.sort(([, timeA], [, timeB]) => {
    const dateA = new Date(`2000-01-01 ${timeA}`);
    const dateB = new Date(`2000-01-01 ${timeB}`);
    return dateA - dateB;
  });

  // Check if every time is not an empty string
  const allTimesValid = timingsArray.every(([, time]) => time !== '');

  // Ensure timings is not empty and all times are not empty strings
  if (!timingsArray.length || !allTimesValid) {
    return null; // Render nothing if timings are missing, empty, or contain empty strings
  }

  return (
    <div className="Timings_table">
      <div className="Timings_table_title">Timings of tour</div>
      <div className="table-container">
        <table className="responsive-table">
          <tbody>
            {timingsArray.map(([place, time], index) => (
              <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                <td className="Place">{place}</td>
                <td className="Time">{time}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Timings_table;
