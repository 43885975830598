import React from 'react'
import './Excluded.css'

const Excluded = ({excludes}) => {
  return (
   <div className="Excluded">
  <div className="Excluded_title">
         Whats Excluded?
      </div>
      <div className="Excluded_list">
      <ul>
      {excludes.map((text, index) => (
        <li key={index} className='Excluded_text'>{text}</li>
      ))}
    </ul>
      </div>
   </div>
  )
}

export default Excluded