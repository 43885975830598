import React from "react";
import "./Package_feature.css";

const Package_feature = ({ icon, text, key, index, totalFeatures }) => {
  const shouldDisplayDivider = index !== totalFeatures - 1;

  return (
    <div className="Package_feature_row">
      <div className="Package_features_column" key={key}>
        {icon}
        <div className="Package_feature_icon_title">{text}</div>
      </div>
      {/* {1 == 2 ? <div className="divider"></div>:<div></div>} */}
    </div>
  );
};

export default Package_feature;
