import "./App.css";
import All_blogs from "./components/All_blogs/All_blogs";
import All_packages from "./components/All_packages/All_packages";
import Blog_detail from "./components/Blog_detail/Blog_detail";
import Contact_us from "./components/Contact_us/Contact_us";
import Floating_menu from "./components/Floating_menu/Floating_menu";
import Home from "./components/Home/Home";
import Package_detail from "./components/Package_detail/Package_detail";

import { Route, Routes, BrowserRouter } from "react-router-dom";

import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";

import avatar from "./images/avatar.jpg";
import WhatsAppFab from "./components/Whatsapp_fab/Whatsapp_fab";
import { toast } from "react-toastify";
import { doc, setDoc } from "firebase/firestore";
import { db } from "./components/Firebase";
import {
  FaEnvelope,
  FaFacebookF,
  FaGoogle,
  FaInstagram,
  FaMapMarked,
  FaMobileAlt,
} from "react-icons/fa";
import logo1 from "./images/logo1.png";
const steps = [
  {
    id: "1",
    message: "Hi there..",
    trigger: "2",
  },
  {
    id: "2",
    message:
      "I think you had some issues it may be better discussing detail in a call.. Enter your phone number..",
    trigger: "3",
  },

  {
    id: "3",
    user: true,
    validator: (value) => {
      if (isNaN(value)) {
        return "Please enter a valid phone number";
      } else {
        if (value.length < 10) {
          return "Please enter a valid 10 digit phone number";
        } else {
          Post_phone_number(value);
          return true;
        }
      }
    },
    trigger: "4",
  },
  {
    id: "4",
    message: "We got you and you ill recieve a call in few minutes",
    end: true,
  },
];

// Creating our own theme
const theme = {
  background: "#efeffd",
  headerBgColor: "#363AED",
  headerFontSize: "13px",
  headerFontColor: "#f4f4f4",
  botBubbleColor: "#FFFFFF",
  botFontColor: "363AED",
  userBubbleColor: "#363AED",
  userFontColor: "white",
};

// Set some properties of the bot
const config = {
  botAvatar: avatar,
  floating: true,
};

const Post_phone_number = async (value) => {
  await setDoc(
    doc(db, "Chatbot_callbacks", value),
    {
      Phone: value,
    },
    { merge: true }
  )
    .then(() => {
      toast("We successfully recieved your request", {
        position: toast.POSITION.BOTTOM_LEFT,
        className: "toast-message",
      });
    })
    .catch((error) => {
      toast(error.toString(), {
        position: toast.POSITION.BOTTOM_LEFT,
        className: "toast-message",
      });
    });

  return true; // Proceed to the next step
};

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="Package_detail" element={<Package_detail />} />
          <Route path="All_packages" element={<All_packages />} />
          <Route path="Package_detail/:id" element={<Package_detail />} />
          <Route path="Contact_us" element={<Contact_us />} />
          <Route path="All_blogs" element={<All_blogs />} />
          <Route path="/Blog_detail/:id" element={<Blog_detail />} />
        </Routes>
        <ThemeProvider theme={theme}>
          <ChatBot
            // This appears as the header
            // text for the chat bot
            headerTitle="Hi tourist any help??"
            steps={steps}
            {...config}
          />
        </ThemeProvider>

        <Floating_menu />
      </BrowserRouter>

      <footer class="footer-distributed">
        <div class="footer-left">
          <h3></h3>

          {/* <p class="footer-links">
            <a href="#" class="link-1">
              Home
            </a>




            <a href="#">Pricing</a>

            <a href="#">About</a>

            <a href="#">Faq</a>

            <a href="#">Contact</a>
          </p> */}
          <img
            src={logo1}
            alt="logo"
            height={"130px"}
            style={{
              backgroundColor: "black",
              borderRadius: "40%", // This makes the image round
            }}
            className="Applogofooter"
          />
          <p class="footer-company-name">Travel Hyderabad © 2023</p>
        </div>

        <div class="footer-center">
          <div className="Footercontact">
            <span className="nobg">
              <FaMapMarked />
            </span>
            <p>
              <span>19-1-873-/6/B/A</span> Bandal Guda
              <span>BahadurPura Hyderabad, Telangana</span>
              <span>India-500064</span>
            </p>
          </div>

          
          <div className="Footercontact">
            <span className="nobg">
              <FaMobileAlt />
            </span>
            <p>9666358080</p>
          </div>
          
          <div className="Footercontact">
            <span className="nobg">
              <FaMobileAlt />
            </span>
            <p>9573711494</p>
          </div>

          <div>
            <span className="nobgs">
              <FaEnvelope />
            </span>
            <p>
              <a href="mailto:support@company.com">
                travelhyderabad12@gmail.com
              </a>
            </p>
          </div>
        </div>

        <div class="footer-right">
          <p class="footer-company-about">
            <span>About the company</span>
            "Welcome to travelhyderabad.in gateway to unforgettable travel
            experiences. With a passion for exploration and a commitment to
            excellence, we have been at the forefront of the tourism industry
            from last 5 years. Our mission is to create magical journeys that
            leave a lasting impression, allowing travelers to discover the
            world's wonders in style and comfort. Whether you're seeking a
            relaxing beach getaway, an adventurous trek through breathtaking
            landscapes, or a cultural immersion in vibrant cities,
            travelhyderabad.in is here to turn your dreams into reality. Let us
            be your trusted companion on your next adventure, and together,
            we'll embark on a journey of discovery and wonder."
          </p>

          <div class="footer-icons">
            <a href="https://www.facebook.com/profile.php?id=61552056398755" className="Footericon">
              <FaFacebookF />
            </a>
            <a href="https://travelhyderabad.in/" className="Footericon">
              <FaGoogle />
            </a>
            <a href="https://www.instagram.com/travelhyderabad.in/" className="Footericon">
              <FaInstagram />
            </a>
          </div>
        </div>
      </footer>

      {/*       
      <Packages/>
      <Package_detail/> */}
    </div>
  );
}

export default App;
