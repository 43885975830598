import React, { useEffect, useRef, useState } from "react";
import "./Feedbacks.css";
import BlogsjsonData from "../../Blogs_data";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../../Firebase";

const Feedbacks = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchFeedbacks = async () => {
    setLoading(true);
    const q = query(collection(db, "Feedbacks"));
    onSnapshot(q, (querySnapshot) => {
      const feedbacksData = [];
      querySnapshot.forEach((doc) => {
        feedbacksData.push({ id: doc.id, ...doc.data() });
      });
      setFeedbacks(feedbacksData);
      setLoading(false);
    });
  };
  useEffect(() => {
    fetchFeedbacks();
  }, []);

  const containerRef = useRef(null);
  let startX;
  let scrollLeft;

  const handleMouseDown = (e) => {
    e.preventDefault(); // Prevent the default link behavior
    startX = e.pageX - containerRef.current.offsetLeft;
    scrollLeft = containerRef.current.scrollLeft;
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    e.preventDefault(); // Prevent the default link behavior
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust the scrolling speed
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const renderFeedbacks = () => {
    const Feedbacks = [];

    for (let i = 0; i < feedbacks.length; i++) {
      const Feedback = feedbacks[i];

      Feedbacks.push(
        <div key={i} className="Feedback_home">
          {" "}
          <div className="Feedback_package">{Feedback.Packagename}</div>
          <div className="Feedback_text">{Feedback.Feedback}</div>
          <div className="FeedbackName">-{Feedback.Username}</div>
        </div>
      );
    }

    return Feedbacks;
  };

  return (
    <div className="Feedbacks">
      <div className="Feedbacks_title">Customer feedbacks</div>

      <div
        className="scroll-container-feedbacks"
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseUp}
        onMouseUp={handleMouseUp}
      >
        {renderFeedbacks()}
      </div>

      <div className="Sized_box_height100"></div>
    </div>
  );
};

export default Feedbacks;
