import React from "react";
import "./Floating_menu.css";
import { AiOutlineHome } from "react-icons/ai";
import { BiWorld, BsTelephone } from "react-icons/bi";
import { IoNewspaperOutline } from "react-icons/io5";
import { FiPhone } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Tooltip as ReactTooltip, Tooltip } from "react-tooltip";

const Floating_menu = () => {
  return (
    <div className="Floating_menu">
      <div className="floating-menu">
        <Link to="/">
          <div
            className="menu-item"
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Home"
            data-tooltip-place="top"
          >
            {" "}
            {/* Add data-tip attribute */}
            <AiOutlineHome className="icon" />
          </div>
        </Link>
        <Link to="/All_packages">
          <div
            className="menu-item"
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Packages"
            data-tooltip-place="top"
          >
            {" "}
            {/* Add data-tip attribute */}
            <BiWorld className="icon" />
          </div>
        </Link>
        <Link to="/All_blogs">
          <div
            className="menu-item"
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Blogs"
            data-tooltip-place="top"
          >
            {" "}
            {/* Add data-tip attribute */}
            <IoNewspaperOutline className="icon" />
          </div>
        </Link>
        <Link to="/Contact_us">
          <div
            className="menu-item"
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Contact us"
            data-tooltip-place="top"
          >
            {" "}
            {/* Add data-tip attribute */}
            <FiPhone className="icon" />
          </div>
        </Link>
        <Tooltip id="my-tooltip" className="Tooltip" classNameArrow="Tooltip_arrow"/>
      </div>
    </div>
  );
};

export default Floating_menu;
