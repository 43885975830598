import React from "react";
import "./Package_main_details.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCar,
  faCoffee,
  faClock,
  faMonument,
} from "@fortawesome/free-solid-svg-icons";

const Feature_data = [
  { icon: <FontAwesomeIcon icon={faCar} />, text: "Cab" },
  { icon: <FontAwesomeIcon icon={faCoffee} />, text: "Breakfast" },
  { icon: <FontAwesomeIcon icon={faClock} />, text: "4 days" },
  { icon: <FontAwesomeIcon icon={faMonument} />, text: "8 Places" },
];

const Package_main_details = ({ title, organizer, main_details }) => {
  // Check if main_details is an array and every item in the array is not an empty string
  const shouldRenderFeatures =
    Array.isArray(main_details) &&
    main_details.length > 0 &&
    main_details.every((item) => item !== "");

  return (
    <div className="Package_main_details">
      <div className="Package_organizer_">Tour organizer : {organizer}</div>
      <div className="Package_title_">{title}</div>
      <div className="Vertical_divider_"></div>
      {shouldRenderFeatures && (
        <div className="Package_features_row_">
          {main_details.map((Detail, index) => (
            <div className="Package_feature_row_" key={index}>
              {Feature_data[index].icon}
              <div className="Package_feature_icon_title_">{Detail}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Package_main_details;
