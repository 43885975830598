import React, { useEffect, useState } from "react";
import "./Contact_us.css";
import { HiOutlinePhone } from "react-icons/hi";
import WhatsAppFab from "../Whatsapp_fab/Whatsapp_fab";
import { collection, doc, onSnapshot, orderBy, query, setDoc } from "firebase/firestore";
import { db } from "../Firebase";
import { toast } from "react-toastify";
const Contact_us = () => {
  const [Phonenumbers, setPhonenumbers] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchPhonenumbers = async () => {
    setLoading(true);
    const q = query(collection(db, "Contact_us"),orderBy("createdAt", "asc"));
    onSnapshot(q, (querySnapshot) => {
      const Phonenumbers = [];
      querySnapshot.forEach((doc) => {
        Phonenumbers.push(doc.data());
      });
      setPhonenumbers([...Phonenumbers]);
      setLoading(false);
    });
  };
  const PhoneNumberLink = ({ phoneNumber }) => {
    return (
      <a href={`tel:${phoneNumber}`} className="phone-icon red">
        <HiOutlinePhone />
      </a>
    );
  };
  useEffect(() => {
    fetchPhonenumbers();
  }, []);
  const handleContactClick = () => {
    if (Phonenumbers.length > 0) {
      window.location.href = `tel:${Phonenumbers[0]}`;
    }
  };

  const isValidPhoneNumber = (phoneNumber) => {
    // Regular expression for valid phone number
    const phonePattern = /^(\+?\d{10}|\+?\d{11}|\+?\d{12})$/;
    return phonePattern.test(phoneNumber);
  };

  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    return emailPattern.test(email);
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const isButtonClickable =
    name.length > 4 && isValidEmail(email) && isValidPhoneNumber(phone);

  const handleRequestCallback = async () => {
    if (isButtonClickable) {
      setLoading(true);

      await setDoc(
        doc(db, "Callbacks", phone),
        {
          Username: name,
          Email: email,
          Phone: phone,
        },
        { merge: true }
      )
        .then(() => {
          toast("Callback recieved successfully", {
            position: toast.POSITION.BOTTOM_LEFT,
            className: "toast-message",
          });

          setName("");
          setEmail("");
          setPhone("");
        })
        .catch((error) => {
          toast(error.toString(), {
            position: toast.POSITION.BOTTOM_LEFT,
            className: "toast-message",
          });
        });

      setLoading(false);
    }
  };

  return (
    <div className="Contact_us_page">
      <div className="Contact_us_row">
        <div className="Sized_box_height100"></div>
        <div className="Contact_us_container">
          <div className="Contact_us_title_">Contact us</div>

          <div className="Phone_no_block_">
              <div className="Phone_no_">Travelhyderabad12@gmail.com</div>
            </div>

          {Phonenumbers.map((Phonenumber, index) => (
            <div className="Phone_no_block_" key={index}>
              <div className="Phone_no_">{Phonenumbers[index].Phone}</div>
              <PhoneNumberLink phoneNumber={Phonenumbers[index].Phone} />
            </div>
          ))}

          

          <div className="Callback_button" onClick={handleContactClick}>
            Contact us
          </div>
        </div>

        <div className="Sized_box_height100"></div>

        <div className="Callback_container">
          <div className="Callback_title">Request Call back</div>
          <input
            placeholder="Name..."
            className="Name_field_callback_"
            required
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            placeholder="Email..."
            className="Email_field_callback_"
            required
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            placeholder="Phone..."
            className="Phone_field_callback_"
            required
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <div
            className="Callback_button"
            onClick={handleRequestCallback}
            style={{ opacity: isButtonClickable ? 1 : 0.6 }}
          >
            Request Call back
          </div>
        </div>

        <div className="Sized_box_height100"></div>
      </div>

      <WhatsAppFab />
    </div>
  );
};

export default Contact_us;
