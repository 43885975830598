import React from 'react';
import './Welcome.css';

const Welcome = () => {
  return (
    <div className="Welcome">
      <div className="TextContainer">
        Discover the Charms of <div className="Hyderabad">
        Hyderabad
        </div>
      </div>
    </div>
  );
}

export default Welcome;
