import React, { useState } from 'react';
import './Feedback.css';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../Firebase';
import { ToastContainer, toast } from 'react-toastify';

const Feedback = ({Packagename}) => {
  const [name, setName] = useState('');
  const [feedback, setFeedback] = useState('');

  const isButtonClickable = name !== '' && feedback !== '';

  const [loading, setLoading] = useState(false);
  const handlePostFeedback = async () => {
    if (isButtonClickable) {
      setLoading(true);

      await setDoc(
        doc(db, 'Feedbacks', name),
        {
          Username: name,
          Feedback: feedback,
          Packagename:Packagename,

        },
        { merge: true }
      )
        .then(() => {
          toast('Feedback posted successfully', {
            position: toast.POSITION.BOTTOM_LEFT,
            className: 'toast-message'
        });

          // Clear the fields after successful submission
          setName('');
          setFeedback('');
        })
        .catch(error => {
          toast(error.toString(), {
            position: toast.POSITION.BOTTOM_LEFT,
            className: 'toast-message'
        });
        });

      setLoading(false);
    }
  };

  return (
    <div className="Feedback">
      <div className="Feedback_title">Feedback on this package</div>
      <div className="Feedback_main">
        <input
          placeholder="Name..."
          className="Feedback_name_field"
          required
          type="text"
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <textarea
          placeholder="Feedback..."
          className="Feedback_field increased-height"
          required
          value={feedback}
          onChange={e => setFeedback(e.target.value)}
        />
        <button
          className="Callback_button"
          onClick={handlePostFeedback}
          disabled={!isButtonClickable || loading}
          style={{ opacity: isButtonClickable ? 1 : 0.6 }}
        >
          {loading ? 'Posting...' : 'Post Feedback'}
        </button>
      </div>
      
    </div>
  );
};

export default Feedback;
